// components/Navbar.js
import React, { useState } from 'react';
import logo from '../assets/logo.png';
import banner from '../assets/banner.jpg';
import android from '../assets/android.png';
import windows from '../assets/windows.png';
import bannerVideo from '../assets/bannervideo.mp4';
import shortbannervideo from '../assets/test.gif';
import secondbanner from '../assets/secondbanner.jpeg';
import mothercoin from '../assets/mothercoin.png';
import motherwallet from '../assets/motherwallet.png';
import motherexchange from '../assets/mother-exchange.png';
import mother7defi from '../assets/mother7defi.png';
import mothernft from '../assets/mothernft.png';
import mothercoinmarket from '../assets/mothercoinmarket.png';
import motherusd from '../assets/mother-usd.png';
import apps_phone from '../assets/apps-phone.png';
import shortlogo from '../assets/shortlogo.PNG';
import appstore from '../assets/appstore.gif';
import googleplay from '../assets/googleplay.gif';
import apkimage from '../assets/apkimage.gif';
import phone3 from '../assets/phone3.jpg';
import doublephone from '../assets/doublephone.jpg';
import downlaodwindows from '../assets/downlaodwindows.PNG';
import serverwrack from '../assets/serverwrack.jpg';
import mcoin from '../assets/mcoin.png';
import musd from '../assets/musd.png';
import motherworldchain from '../assets/motherworldchain.png'
import swapservice from '../assets/swapservice.png';
import kraken from '../assets/kraken.png';
import visa from '../assets/visa.png';
import mastercard from '../assets/mastercard.png';
import emiratenbd from '../assets/emiratednbd.png';
import commercialbankdubai from '../assets/commercialbankdubai.png';
import bitcoin from '../assets/bitcoin.png';
import etherium from '../assets/etherium.png';
import payment from '../assets/payment.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import MultiStepSignupModal from './MultiStepSignupModal';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


import './Navbar.css'; // Import the CSS file

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="maindiv">
       <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <nav className="navbar">
        <div className="logo">
          <img src={logo} alt="Logo" style={{ width: '200px', height: '50px' }} />
          <div className="hamburger" onClick={toggleDropdown}>
            ☰
          </div>
          {dropdownVisible && (
          <div className="dropdown">
            <a href="#mother_menu">Mother Menu</a>
            <a href="#mother_cryptocurrency">Mother Cryptocurrency</a>
          </div>
        )}
        </div>

        <div className="navbar-links">
          <a href="#wallet">Mother</a>
          <a href="#wallet">Wallet</a>
          <a href="#exchange">Exchange</a>
          <a href="#defi">7DeFi</a>
          <a href="#trade">Trade</a>
          <a href="#crypto_market">Crypto Market</a>
          <a href="#nft">NFT</a>
        </div>

        <div className="auth-options">
        <Link to="/login"><p>Login</p></Link>
          <Link to="/signup"><p>Register</p></Link>
          <a href="#register"></a>
        </div>

       
      </nav>

      {/* Add the banner section below the Navbar */}
      <div className="banner">
      <video loop muted autoPlay playsInline  className="banner-video">
          <source src={bannerVideo} type="video/mp4" media="(min-width: 768px)"/>
          <source src={shortbannervideo} type="video/mp4" media="(max-width: 767px)"></source>
          Your browser does not support the video tag.
        </video>
        <div className="banner-content">
          <p className="bannerparagraph">Mother World Chain Mother Coin and Mother MUSD</p>
          <h1 className="decentralized" style={{ display: 'inline' }}>
            <span style={{ color: 'blue' }}>DECENTRALIZED</span> <span style={{ color: 'yellow' }}>EXCHANGE</span>
          </h1>
          <p className="bannerparagraphnext">Mother Decentralized exchange trades a worldwide auto time watch innovative system.</p>
        </div>
        <div className="banner-contentnext">
         <p>  Worldwide Mother, the only one crypto platform. Any bank & virtual card, P2P, C2C, crypto buy, sell, trade, hold & earn.
  Cryptocurrencies instant withdraw on Mother.</p>
        </div>

        <div className="banner-contentright">
         <p>Starting Worldwide</p>
         <p>Listing Crypto 100%</p>
        </div>
      </div>


      <div className="signup-section">
     
        <div className="signup-buttons">
        <button className="signup-button">
            <FontAwesomeIcon icon={faUser} className="icon" />
            
            <Link to="/signup">Sign up with Email or Phone</Link>

          </button>
          <button className="signup-button">KYC complete, create, connecting M-ID</button>
          {isModalOpen && <MultiStepSignupModal closeModal={closeModal} />}

        </div>


        <div className="google-buttons">
        <button className="google-button">
            <FontAwesomeIcon icon={faGoogle} className="icon" />
             Google
          </button>

          <button className="apple-button">
            <FontAwesomeIcon icon={faApple} className="icon" />
            Apple
          </button>

          <button className="android-button">
          <img src={android} className="android-icon" alt="Logo" style={{ width: '20px', height: '18px' }} />
            Download APK
          </button>

          <button className="android-button">
          <img src={windows} className="android-icon" alt="Logo" style={{ width: '20px', height: '18px' }} />
            Windows
          </button>
          
        </div>
        
      </div>

      <div>
      </div>

      <div className="crypto-business-section">
        <div className="column-container">
          <div className="column">
            <h3>Worldwide connecting crypto business </h3>
            <h3>Mother Wallet and Mother exchange.</h3>
          </div>
          <div className="column-first">
            <h2>$5 Billion</h2>
            <p>24h trading volumn on Mother Exchange</p>
          </div>
          <div className="column">
            <h3>100+</h3>
            <p>Cryptocurrencies Listed</p>
          </div>
          <div className="column">
            <h3>1 Million</h3>
            <p>Registered Users</p>
          </div>

          <div className="column">
            <h3>0.07% </h3>
            <p>Lowest Transaction Fees</p>
          </div>
        </div>
      </div>

        {/* Add the second banner section below the Navbar */}
        <div className="secondbanner">
          <img src={secondbanner} alt="Second Banner" />
        <div className="secondbanner-content">
        <div className="image-container">
          <div className="image-row">
            <img src={mothercoin} alt="Mother Coin" />
            <img src={motherwallet} alt="Mother Wallet" />
            <img src={motherexchange} alt="Mother Exchange" />
            <p className="right-paragraph">All Cryptocurrency Project License by the Government of <br></br>Dubai, USA,  Canada, UK, Germany, Australia & Singapore</p>
          </div>
        <div className="image-row">
            <img src={mother7defi} alt="7DeFi" />
            <img src={mothernft} alt="NFT" />
            <img src={mothercoinmarket} alt="Mother USD" />
            
        </div>
        <div className="image-row">
            <img src={motherusd} alt="Mother USD" />
        </div>
        </div>

        

        </div>


        </div>


      {/* Add the full-width paragraph section */}
      <div className="full-width-paragraph">
        <h1>Decentralized Exchange (DEX) Mother Wallet International </h1>
        <h2>In the world 1<sup>st</sup> time of Cryptocurrency Multiple asset Digital Connected Wallet & Exchange. </h2>
      </div>

      {/* Add the two-column section */}
    <div className="two-column-section">
  {/* Left Column */}
      <div className="two-columnleft-column">
        <p>Mother 7 Country’s Government license issuing Dubai, USA, UK, Canada, Germany, Australia & Singapore. 25 Country’s Mother Partnership C2C Client to Counter Office. Face to face, Credit or Debit Visa Card, Virtual card, Master card crypto & local currency Buy, Sell & Withdraw Instant Cash local Currency. Worldwide 10 language setup Mother Wallet International.<br></br>

Mother Wallet Fully International Business Wallet. All API Automatic Coin Setup. Unlimited Store, Manage, Swap, Collect NFTs and trade to coin or token 0% fee Transfer Wallet to Direct Mother Exchange. Worldwide Fortunate 3 Million People free Day, Weekly & Monthly Earn Mother Coin, Mother MUSD, USDT & Crypto. Join Mother Wallet More than World Running 61 Blockchain supported and 1 Million+ assets supported in ONE Digital Mother App.</p>
    {/* Four images in a row */}
    <div className="two-columnimage-row">
      <img src={shortlogo} alt="AppPhone" />
      <img className="two-columnimage-row-image" src={googleplay} alt="AppPhone" />
      <img className="two-columnimage-row-image" src={appstore} alt="AppPhone" />
      <img className="two-columnimage-row-image" src={apkimage} alt="AppPhone" />
    </div>

    </div>

  {/* Right Column */}
  <div className="two-columnright-column">
    {/* Single image in the right column */}
    <img src={apps_phone} alt="AppPhone" />
  </div>
  </div>

  <div className="full-width-paragraph">
        <h1>Mother Centralized Hybrid Exchange (CEX)</h1>
        <h2>In the 1<sup>st</sup> multiple asset Digital Connected Wallet & Exchange hybrid exchange. </h2>
      </div>

   {/* Add the two-column section */}
   

  <div className="three-column-section">
  {/* First Column */}
  <div className="three-column-sectioncolumn">
    <img src={doublephone} alt="First Image" />
  </div>

  {/* Second Column */}
  <div className="three-column-sectioncolumn">
    <p>
    Mother 7 Country’s Government license issuing Mother Exchange Worldwide Self-Server Setup in Dubai, USA, UK, Canada, Germany, Australia & Singapore Mother and 25 Country’s Mother Partnership C2C Client to Counter Office. Face to face, Credit or Debit Visa Card, Virtual card & Master card crypto & local currency Buy, Sell & Withdraw Instant Cash local Currency. Worldwide 10 language setup Mother International.<br></br>

Home, Market, Trade, Spot, NFTs Trade, Futures Wallet, Crypto Transfer and Mother Wallet Connected, Profile. 1 KYC Mother 7 Project Activated, All Message, Mother Customer Support, Live Chat, C2C, and trade to coin or token Transfer direct crypto safe Mother Wallet to Mother Exchange.
 
    </p>
  </div>

  {/* Third Column */}
  <div className="three-column-sectioncolumn">
    <div className="row">
      <img src={phone3} alt="Second Image" />
    </div>
    <div className="row">
      <p>
      Day, Weekly & Monthly earn USDT & Crypto. Join Mother Exchange more than 54 blockchain assets supported in one Mother Exchange</p>
    </div>
  </div>
</div>

<div className="fourimage-row">
      <img className="fourimage-rowimage" src={googleplay} alt="AppPhone" />
      <img className="fourimage-rowimage" src={appstore} alt="AppPhone" />
      <img className="fourimage-rowimage" src={apkimage} alt="AppPhone" />
      <img className="fourimage-rowimage" src={downlaodwindows} alt="AppPhone"/>
    </div>

    <div className="process-section">
  {/* First Column */}
  <div className="process-sectionleftcolumn">
  <h1>Build Your Mother Crypto Process</h1>
  <p>Start your trade & received bonus with Mother Exchange</p>
  <h3>Account Opening the Mother</h3>
  <p>Complete the Application form fillup your account.</p>
  <h3>Verify Your Identity</h3>
  <p>Complete your identity verification process to secure your account and transactions.</p>
  <h3>Start Trading</h3>
  <p>You're good to go! Buy/Sell Crypto. You can add funds with a variety of payment methods.</p>
  <h3>First Bonus Your Account</h3>
  <p>First Deposit any amount bonus 10% to 1000%</p>
  <h3>Buy MUSD Mother Exchange</h3>
  <p>2500$ to 25000$ buy MUSD received + 20% Mother Coin</p>

  <button>Get Started</button>
  </div>

  {/* Third Column */}
  <div className="process-sectionrightcolumn">
    <div className="row">
      <img src={serverwrack} className="process-sectioncolumnimage" alt="Second Image" />
    </div>
    <div className="row">
      <h2>
        Mother Crypto Mining Technology System in Dubai
      </h2>
    </div>
  </div>
</div>

      <div className="full-width-paragraph">
        <h1>Welcome to Mother World Chain</h1>
        <p>Mother's self-sacrifice for the child, full of dreams and eternally selfless, the compassion bound by that love, the price of <br></br> this self-sacrifice of the child's mother, even if the world is sold, the child will not be able to pay the price or it is not possible & impossible.</p>  
        <div className="welcomemother-chain-section">
  {/* First Column */}
      <div className="welcomemother-chainleftcolumn">
      <div className="row">
       <img src={mcoin} className="process-sectioncolumnimage" alt="Second Image" />
       
      </div>

      <div className="row">
       <img src={musd} className="process-sectioncolumnimage" alt="Second Image" />
       
      </div>
      </div>

    {/* Third Column */}
      <div className="welcomemother-chainrightcolumn">
      <div className="welcomemother-chainrightcolumnrow">
        <h3>So, On Behalf of Mother World Chain, respectful greeting to all the Mother of the World.</h3><br></br>
        <h2>Mother World Chain (MOTHER)</h2> <br></br>
        <p>The Mother a Scalable, Sustainable self Blockchain</p><br></br>

        <h2>Mother USD (MUSD)</h2> <br></br>
        <p>The Mother USD the world first Select StableCoin </p><br></br>
        <p> Buy-Sell can be found it started an exchange</p>

        
      </div>
    
      </div>
    </div>

    <div className="welcomemother-chainheading">
      <h1>Mother Exchange + Binance Exchange +World Top 50 Exchange</h1>
    </div>
      
      </div>

      <div className="banner">

      <img className="motherworldchain" src={motherworldchain} alt="Second Image" />

      </div>

      <div className="banner">

      <img className="swapservice" src={swapservice} alt="Second Image" />

      </div>

      <div className="full-width-paragraph">
       

    
      </div>
      <div className="full-width-paragraph">
      <h1>MOTHER EXCHANGE PARTNERSHIP</h1>
        <p>Mother World is safe from all evils. Worldwide only one crypto platform any country any bank any virtual card, P2P, C2C, Crypto buy, sale, trade, hold and extra earn. Cryptocurrency instant Withdraw in Mother Wallet & Mother Exchange.</p>  
     
      </div>
      <div className="banner">

      <img className="krakens" src={kraken} alt="Second Image" />

      </div>

  <div class="addresssection">
    <div class="column">
      <h3>DUBAI OFFICE</h3>
      <p>
      Mother Wallet International (MOTHER)<br></br>
Downtown Dubai (5th floor), Sheikh Zayed Road, 126272, DUBAI, United Arab Emirates
PHONE: +971 56 179 1590
Contact Email- support@mothercoin.in
motherwallet8@gmail.com
      </p>
  
  </div>

  <div class="column">
    <h3>USA OFFICE</h3>
   
  </div>

  <div class="column">
    <h3>UK OFFICE</h3>
   
  </div>

  <div class="column">
    <h3>CANADA OFFICE</h3>
   <p>Azharul Haque,<br></br>
200 Murison Blvd,
Unit 67 Scarborough. ON.
M1B3R9. Canada.<br></br>
Mobile:- +16476492129</p>
  </div>
</div>

<div style={{marginTop:'-10px'}} className="acceptedpayment">
  <h1>We Accept:</h1>

  <div className="fourimage-row">
      <img className="fourimage-rowimage" src={visa} alt="AppPhone" />
      <img className="fourimage-rowimage" src={mastercard} alt="AppPhone" />
      <img className="fourimage-rowimage" src={commercialbankdubai} alt="AppPhone"/>
      <img className="fourimage-rowimage" src={bitcoin} alt="AppPhone"/>
      <img className="fourimage-rowimage" src={etherium} alt="AppPhone"/>
    </div>
</div>

<div class="footersection">
    <div class="footersectioncolumn">
      <h3>About Us</h3>
      <ul>
        <li><a>About us</a></li>
        <li><a>Community</a></li>
        <li><a>Blog</a></li>
      </ul>
  
  </div>

  <div class="footersectioncolumn">
    <h3>Products</h3>
    <ul>
        <li><a>About us</a></li>
        <li><a>Community</a></li>
        <li><a>Blog</a></li>
      </ul>
   
  </div>

  <div class="footersectioncolumn">
    <h3 className="support">Support</h3>
    <ul>
        <li className="support"><a>About us</a></li>
        <li className="support"><a>Community</a></li>
        <li className="support"><a>Blog</a></li>
      </ul>
   
  </div>

  <div class="footersectioncolumn">
    <h3 className="support">Service</h3>
    <ul className="support">
        <li className="support"><a>About us</a></li>
        <li className="support"><a>Community</a></li>
        <li className="support"><a>Blog</a></li>
      </ul>
  
  </div>
</div>

<div className="full-width-paragraph">
        <p>Copyright © 2024 Mother Wallet & Mother Exchange in the World Setup in Dubai. Website Design & Development By Wallet Software Team in Dubai</p>  
     
      </div>




    



    </div>
  );
};

export default Navbar;
