import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import SignupPage from './components/SignupPage';
import Registration from './components/Registration';
import Dashboard from './components/Dashboard';
import Mining from './components/Mining'
import Login from './components/LoginPage'
import './components/styles.css'; // Import the styles

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navbar />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/mining" element={<Mining />} />
        <Route path="/login" element={<Login/>} />
      </Routes>
    </Router>
  );
}

export default App;
