import React, { useState , useEffect } from 'react';
import logo from '../assets/logo.png';

import visa from '../assets/visa.png';
import mastercard from '../assets/mastercard.png';
import emiratenbd from '../assets/emiratednbd.png';
import commercialbankdubai from '../assets/commercialbankdubai.png';
import bitcoin from '../assets/bitcoin.png';
import etherium from '../assets/etherium.png';
import { Button, Form } from 'react-bootstrap'; // Import necessary Bootstrap components
import payment from '../assets/payment.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import MultiStepSignupModal from './MultiStepSignupModal';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import { getAuth } from "firebase/auth";
import axios from 'axios'; // Import Axios for making HTTP requests
import { Link, useNavigate , useHistory,useParams  } from 'react-router-dom';


import './Navbar.css'; // Import the CSS file

const Registration = ({ location = {} }) =>  {

  const auth = getAuth();
  const user = auth.currentUser;
  const email = user.email;
  const displayName = user.displayName;
  const navigate = useNavigate ();
    

  const [formData, setFormData] = useState({
    fullName: displayName || '',
    username: '',
    email: email || '',
    phone: '',
    photo: '',
    mIdNumber: '',
    nidPassportNumber: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to your Express server endpoint
      const response = await axios.post('https://nodes.motherworldchain.com/registration', {
        fullName: displayName || '',
        username: formData.username,
        email: email || '',
        phone: formData.phone,
        photo: formData.photo,
        mIdNumber: formData.mIdNumber,
        nidPassportNumber: formData.nidPassportNumber,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      });


      // Handle the response, you can log it or show a success message to the user
      console.log('Registration successful:', response.data);
      navigate('/dashboard', { state: {email } });
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };

  if (user !== null) {
    // The user object has basic properties such as display name, email, etc.
    const displayName = user.displayName;
    const email = user.email;
    const photoURL = user.photoURL;
    const emailVerified = user.emailVerified;
  
    // The user's ID, unique to the Firebase project. Do NOT use
    // this value to authenticate with your backend server, if
    // you have one. Use User.getToken() instead.
    const uid = user.uid;
    console.log('User Email:', email);
  }
  

    const [dropdownVisible, setDropdownVisible] = useState(false);
    //const [userEmail, setUserEmail] = useState('');
   // console.log('Location State:', location.state);
  
    const userEmail = location.state && location.state.userEmail;
    
   
  
  
    
  
    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };
  
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    
    return (
      <div className="maindiv">
        <nav className="navbar">
          <div className="logo">
            <img src={logo} alt="Logo" style={{ width: '200px', height: '50px' }} />
            <div className="hamburger" onClick={toggleDropdown}>
              ☰
            </div>
            {dropdownVisible && (
            <div className="dropdown">
              <a href="#mother_menu">Mother Menu</a>
              <a href="#mother_cryptocurrency">Mother Cryptocurrency</a>
            </div>
          )}
          </div>
  
          <div className="navbar-links">
            <a href="#wallet">Mother</a>
            <a href="#wallet">Wallet</a>
            <a href="#exchange">Exchange</a>
            <a href="#defi">7DeFi</a>
            <a href="#trade">Trade</a>
            <a href="#crypto_market">Crypto Market</a>
            <a href="#nft">NFT</a>
          </div>
  
          <div className="auth-options">
            <a href="#login">Login</a>
            <a href="#register">Register</a>
          </div>
  
         
        </nav>
  
        <div className="container mt-5">
        <Form onSubmit={handleSubmit}>
  <h2 className="mb-4 white-label">Signup Form</h2>

  {/* Full Name */}
  <Form.Group controlId="fullName">
    <Form.Label className="white-label">Full Name</Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter your name"
      value={formData.fullName}
     
    />
  </Form.Group>

  {/* Username */}
  <Form.Group controlId="username">
    <Form.Label className="white-label">Username</Form.Label>
    <Form.Control
    type="text"
    placeholder="Enter your username"
    value={formData.username}
    onChange={handleChange}
    name="username"
  />
  </Form.Group>

  {/* Email */}
  <Form.Group controlId="email">
    <Form.Label className="white-label">Email</Form.Label>
    <Form.Control
      type="email"
      placeholder="Enter your email"
      value={formData.email}
 
    />
  </Form.Group>

  {/* Phone */}
  <Form.Group controlId="phone">
    <Form.Label className="white-label">Phone</Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter your phone number"
      value={formData.phone}
      onChange={handleChange}
      name="phone"
    
    />
  </Form.Group>

  {/* Photo */}
  <Form.Group controlId="photo">
    <Form.Control
      type="hidden"
      placeholder="Enter URL of your photo"
      value={formData.photo}
      onChange={handleChange}
      name="photo"
  
    />
  </Form.Group>

  {/* M-ID Number */}
  <Form.Group controlId="midNumber">
    <Form.Label className="white-label">M-ID Number</Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter your M-ID number"
      value={formData.mIdNumber}
      onChange={handleChange}
      name="mIdNumber"
  
    />
  </Form.Group>

  {/* NID Number or Passport Number */}
  <Form.Group controlId="nidPassportNumber">
    <Form.Label className="white-label">NID Number or Passport Number</Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter your NID or Passport number"
      value={formData.nidPassportNumber}
      onChange={handleChange}
      name="nidPassportNumber"

    />
  </Form.Group>

  {/* Password */}
  <Form.Group controlId="password">
    <Form.Label className="white-label">Password</Form.Label>
    <Form.Control
      type="password"
      placeholder="Enter your password"
      value={formData.password}
      onChange={handleChange}
      name="password"

    />
  </Form.Group>

  {/* Confirm Password */}
  <Form.Group controlId="confirmPassword">
    <Form.Label className="white-label">Confirm Password</Form.Label>
    <Form.Control
      type="password"
      placeholder="Confirm your password"
      value={formData.confirmPassword}
      onChange={handleChange}
      name="confirmPassword"
 
    />
  </Form.Group>

  <br />

  {/* Submit Button */}
  <Button variant="primary" type="submit">
    Submit
  </Button>
</Form>
        </div>
        <br></br>
  
        
  
    <div class="addresssection">
      <div class="column">
        <h3>DUBAI OFFICE</h3>
        <p>
        Mother Wallet International (MOTHER)<br></br>
  Downtown Dubai (5th floor), Sheikh Zayed Road, 126272, DUBAI, United Arab Emirates
  PHONE: +971 56 179 1590
  Contact Email- support@mothercoin.in
  motherwallet8@gmail.com
        </p>
    
    </div>
  
    <div class="column">
      <h3>USA OFFICE</h3>
     
    </div>
  
    <div class="column">
      <h3>UK OFFICE</h3>
     
    </div>
  
    <div class="column">
      <h3>CANADA OFFICE</h3>
     <p>Azharul Haque,<br></br>
  200 Murison Blvd,
  Unit 67 Scarborough. ON.
  M1B3R9. Canada.<br></br>
  Mobile:- +16476492129</p>
    </div>
  </div>
  
  <div className="acceptedpayment">
    <h1>We Accept:</h1>
  
    <div className="fourimage-row">
        <img className="fourimage-rowimage" src={visa} alt="AppPhone" />
        <img className="fourimage-rowimage" src={mastercard} alt="AppPhone" />
        <img className="fourimage-rowimage" src={emiratenbd} alt="AppPhone" />
        <img className="fourimage-rowimage" src={commercialbankdubai} alt="AppPhone"/>
        <img className="fourimage-rowimage" src={bitcoin} alt="AppPhone"/>
        <img className="fourimage-rowimage" src={etherium} alt="AppPhone"/>
      </div>
  </div>
  
  <div class="footersection">
      <div class="footersectioncolumn">
        <h3>About Us</h3>
        <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
    
    </div>
  
    <div class="footersectioncolumn">
      <h3>Products</h3>
      <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
     
    </div>
  
    <div class="footersectioncolumn">
      <h3>Support</h3>
      <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
     
    </div>
  
    <div class="footersectioncolumn">
      <h3>Service</h3>
      <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
    
    </div>
  </div>
  
  <div className="full-width-paragraph">
          <p>Copyright © 2023 Mother Wallet & Mother Exchange in the World Setup in Dubai. Website Design & Development By Wallet Software Team in Dubai</p>  
       
        </div>
  
  
  
  
      
  
  
  
      </div>
    );
  
      
  

};

export default Registration;