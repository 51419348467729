// MultiStepSignupModal.js
import React, { useState } from 'react';
import './MultiStepSignupModal.css';

const MultiStepSignupModal = ({ closeModal }) => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const renderContent = () => {
    // Customize the modal content based on the current step
    switch (step) {
      case 1:
        return (
          <div>
            <h1>Welcome to Mother World Chain All Platform</h1>
            <h4>01. Mother Coin (Mother) </h4>
            <h4>02. Mother USD (MUSD) </h4>
            <h4>03. Mother Virtual Card  </h4>
            <h4>04. Mother Wallet  </h4>
            <h4>05. Mother Hardware Wallet  </h4>
            <h4>06. Mother Exchange  </h4>
            <h4>07. Mother 7 Defi  </h4>
            <h4>08. MotherCoinMarket </h4>
            <h4>09. Mother Money Exchange </h4>
            <h4>10. Mother Forex Brokerage </h4>

            {/* Add content for the first step */}
            <button onClick={handleNext}>Next</button>
          </div>
        );
      case 2:
        return (
          <div>
            <h2>Step 2: Email/Phone Verification</h2>
            {/* Add content for the second step */}
            <button onClick={handleNext}>Next</button>
          </div>
        );
      case 3:
        return (
          <div>
            <h2>Step 3: Complete Sign Up</h2>
            {/* Add content for the third step */}
            <button onClick={closeModal}>Finish</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        {renderContent()}
      </div>
    </div>
  );
};

export default MultiStepSignupModal;
