import React, { useState , useEffect } from 'react';
import logo from '../assets/logo.png';

import visa from '../assets/visa.png';
import mastercard from '../assets/mastercard.png';
import emiratenbd from '../assets/emiratednbd.png';
import commercialbankdubai from '../assets/commercialbankdubai.png';
import bitcoin from '../assets/bitcoin.png';
import etherium from '../assets/etherium.png';
import { Button, Form } from 'react-bootstrap'; // Import necessary Bootstrap components
import payment from '../assets/payment.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import MultiStepSignupModal from './MultiStepSignupModal';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import { getAuth } from "firebase/auth";
import axios from 'axios'; // Import Axios for making HTTP requests
import { Link, useNavigate , useHistory,useParams  } from 'react-router-dom';


import './Navbar.css'; // Import the CSS file

const LoginPage = ({ location = {} }) =>  {

    const [formData, setFormData] = useState({
        email: '',
        password: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };
    
      const handleLogin = async (e) => {
        e.preventDefault();
      
        try {
          // Make a POST request to your Express server endpoint for login
          const response = await axios.post('https://nodes.motherworldchain.com/login', {
            email: formData.email,
            password: formData.password,
          });
      
          // Handle the response, you can log it or perform actions based on success/failure
          console.log('Login successfully:', response.data);
          
          // Assuming your server sends a success message upon successful login
          if (response.data.message === 'Login successful') {
            // Redirect to the dashboard or any other page upon successful login
            navigate('/dashboard');
          }
        } catch (error) {
          console.error('Error during login:', error);
        }
      };

 
  const navigate = useNavigate ();
    

  
  

    const [dropdownVisible, setDropdownVisible] = useState(false);
    //const [userEmail, setUserEmail] = useState('');
   // console.log('Location State:', location.state);
  
    const userEmail = location.state && location.state.userEmail;
    
   
  
  
    
  
    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };
  
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    
    return (
      <div className="maindiv">
        <nav className="navbar">
          <div className="logo">
            <img src={logo} alt="Logo" style={{ width: '200px', height: '50px' }} />
            <div className="hamburger" onClick={toggleDropdown}>
              ☰
            </div>
            {dropdownVisible && (
            <div className="dropdown">
              <a href="#mother_menu">Mother Menu</a>
              <a href="#mother_cryptocurrency">Mother Cryptocurrency</a>
            </div>
          )}
          </div>
  
          <div className="navbar-links">
            <a href="#wallet">Mother</a>
            <a href="#wallet">Wallet</a>
            <a href="#exchange">Exchange</a>
            <a href="#defi">7DeFi</a>
            <a href="#trade">Trade</a>
            <a href="#crypto_market">Crypto Market</a>
            <a href="#nft">NFT</a>
          </div>
  
          <div className="auth-options">
            <a href="#login">Login</a>
            <a href="#register">Register</a>
          </div>
  
         
        </nav>
  
        <div className="container mt-5">
      <h2 className="white-label">Login</h2>
      <Form onSubmit={handleLogin}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="white-label">Email address</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter email"
            required
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label className="white-label">Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
        </Form.Group>
        <br></br>
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
      <p className="mt-3 white-label">
        Don't have an account? <Link to="/signup">Signup</Link>
      </p>
    </div>
        <br></br>
  
        
  
    <div class="addresssection">
      <div class="column">
        <h3>DUBAI OFFICE</h3>
        <p>
        Mother Wallet International (MOTHER)<br></br>
  Downtown Dubai (5th floor), Sheikh Zayed Road, 126272, DUBAI, United Arab Emirates
  PHONE: +971 56 179 1590
  Contact Email- support@mothercoin.in
  motherwallet8@gmail.com
        </p>
    
    </div>
  
    <div class="column">
      <h3>USA OFFICE</h3>
     
    </div>
  
    <div class="column">
      <h3>UK OFFICE</h3>
     
    </div>
  
    <div class="column">
      <h3>CANADA OFFICE</h3>
     <p>Azharul Haque,<br></br>
  200 Murison Blvd,
  Unit 67 Scarborough. ON.
  M1B3R9. Canada.<br></br>
  Mobile:- +16476492129</p>
    </div>
  </div>
  
  <div className="acceptedpayment">
    <h1>We Accept:</h1>
  
    <div className="fourimage-row">
        <img className="fourimage-rowimage" src={visa} alt="AppPhone" />
        <img className="fourimage-rowimage" src={mastercard} alt="AppPhone" />
        <img className="fourimage-rowimage" src={emiratenbd} alt="AppPhone" />
        <img className="fourimage-rowimage" src={commercialbankdubai} alt="AppPhone"/>
        <img className="fourimage-rowimage" src={bitcoin} alt="AppPhone"/>
        <img className="fourimage-rowimage" src={etherium} alt="AppPhone"/>
      </div>
  </div>
  
  <div class="footersection">
      <div class="footersectioncolumn">
        <h3>About Us</h3>
        <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
    
    </div>
  
    <div class="footersectioncolumn">
      <h3>Products</h3>
      <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
     
    </div>
  
    <div class="footersectioncolumn">
      <h3>Support</h3>
      <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
     
    </div>
  
    <div class="footersectioncolumn">
      <h3>Service</h3>
      <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
    
    </div>
  </div>
  
  <div className="full-width-paragraph">
          <p>Copyright © 2023 Mother Wallet & Mother Exchange in the World Setup in Dubai. Website Design & Development By Wallet Software Team in Dubai</p>  
       
        </div>
  
  
  
  
      
  
  
  
      </div>
    );
  
      
  

};

export default LoginPage;