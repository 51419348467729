// SignupPage.js
import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import logo from '../assets/logo.png';
import shortlogo from '../assets/shortlogo.PNG';
import bannerVideo from '../assets/bannervideo.mp4';

import visa from '../assets/visa.png';
import mastercard from '../assets/mastercard.png';
import emiratenbd from '../assets/emiratednbd.png';
import commercialbankdubai from '../assets/commercialbankdubai.png';
import bitcoin from '../assets/bitcoin.png';
import etherium from '../assets/etherium.png';
import payment from '../assets/payment.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import MultiStepSignupModal from './MultiStepSignupModal';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate, useHistory  } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
// Import Firebase configuration
import { firebaseConfig } from './firebase'; // Make sure to adjust the path
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";


import './SignUpPage.css'; // Import the CSS file

const SignupPage = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app); // Initialize authentication
    const navigate = useNavigate ();
    
    const provider = new GoogleAuthProvider();


    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };
  
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const signupWithGoogle = () => {
      signInWithPopup(auth, provider)
        .then((result) => {
          const user = result.user;
          const userEmail = user.email || '';
          console.log('User Email:', userEmail);
  
          // Update the user context
         // setUser(userEmail);
  
          // Add email to URL parameters
          const queryParams = new URLSearchParams();
          queryParams.append('userEmail', userEmail);
  
          // Navigate to /formfillup with email in URL
          navigate('/registration', { state: {userEmail } });


        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);
        });
    };

    
  
    return (
      <div className="maindiv">
        <nav className="navbar">
          <div className="logo">
            <img src={logo} alt="Logo" style={{ width: '200px', height: '50px' }} />
            <div className="hamburger" onClick={toggleDropdown}>
              ☰
            </div>
            {dropdownVisible && (
            <div className="dropdown">
              <a href="#mother_menu">Mother Menu</a>
              <a href="#mother_cryptocurrency">Mother Cryptocurrency</a>
            </div>
          )}
          </div>
  
          <div className="navbar-links">
            <a href="#wallet">Mother</a>
            <a href="#wallet">Wallet</a>
            <a href="#exchange">Exchange</a>
            <a href="#defi">7DeFi</a>
            <a href="#trade">Trade</a>
            <a href="#crypto_market">Crypto Market</a>
            <a href="#nft">NFT</a>
          </div>
  
          <div className="auth-options">
            <a href="#login">Login</a>
            <a href="#register">Register</a>
          </div>
  
         
        </nav>
  
        {/* Add the banner section below the Navbar */}
       
        <video autoPlay muted loop playsInline>
           
          </video>
          <div className="signupbanner-content">
            <h1>Welcome to Mother World Chain All Platform</h1>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}> <img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> Coin (Mother)</span>
            </h2><br></br>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}><img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> USD (MUSD)</span>
            </h2><br></br>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}><img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> Virtual Card </span>
            </h2><br></br>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}><img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> Wallet</span>
            </h2><br></br>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}><img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> Hardware Wallet</span>
            </h2><br></br>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}><img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> Exchange</span>
            </h2><br></br>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}><img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> 7 Defi</span>
            </h2><br></br>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}><img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> Coin Market</span>
            </h2><br></br>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}><img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> Money Exchange</span>
            </h2><br></br>
            <h2 className="decentralized" style={{ display: 'inline' }}>
              <span style={{ color: 'blue' }}><img src={shortlogo} alt="Second Image" /> Mother</span> <span style={{ color: 'yellow' }}> Forex Brokerage</span>
            </h2>
            <br></br>
            <br></br>
          
            <button className="signup-button" onClick={signupWithGoogle}>
            
            <Link to="/signup"><p>Get Started</p></Link>

          </button>
          </div>
          
  
  
  
  
       
  
          {/* Add the second banner section below the Navbar */}
     
        
  <div className="acceptedpayment">
    <h1>We Accept:</h1>
  
    <div className="fourimage-row">
        <img className="fourimage-rowimage" src={visa} alt="AppPhone" />
        <img className="fourimage-rowimage" src={mastercard} alt="AppPhone" />
        <img className="fourimage-rowimage" src={commercialbankdubai} alt="AppPhone"/>
        <img className="fourimage-rowimage" src={bitcoin} alt="AppPhone"/>
        <img className="fourimage-rowimage" src={etherium} alt="AppPhone"/>
      </div>
  </div>
  
  <div class="footersection">
      <div class="footersectioncolumn">
        <h3>About Us</h3>
        <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
    
    </div>
  
    <div class="footersectioncolumn">
      <h3>Products</h3>
      <ul>
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
     
    </div>
  
    <div class="footersectioncolumn">
      <h3 className="support">Support</h3>
      <ul className="support">
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
     
    </div>
  
    <div class="footersectioncolumn">
      <h3 className="support">Service</h3>
      <ul className="support">
          <li><a>About us</a></li>
          <li><a>Community</a></li>
          <li><a>Blog</a></li>
        </ul>
    
    </div>
  </div>
  
  <div className="full-width-paragraph">
          <p>Copyright © 2023 Mother Wallet & Mother Exchange in the World Setup in Dubai. Website Design & Development By Wallet Software Team in Dubai</p>  
       
        </div>
  
  
  
  
      
  
  
  
      </div>
    );
  };
  

export default SignupPage;
