// components/Navbar.js
import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png';

import { Link } from 'react-router-dom';

import './Navbar.css'; // Import the CSS file

const MiningPage = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };

    const [timer, setTimer] = useState(43200); // 12 hours in seconds
    const [isMiningStarted, setIsMiningStarted] = useState(false);
  
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        let interval;
        
        if (isMiningStarted && timer > 0) {
          interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
          }, 1000);
        }
    
        return () => clearInterval(interval);
      }, [isMiningStarted, timer]);

      const startMining = () => {
        setIsMiningStarted(true);
      };
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    
  
    return (
      <div className="maindiv">
        <nav className="navbar">
          <div className="logo">
            <img src={logo} alt="Logo" style={{ width: '200px', height: '50px' }} />
            <div className="hamburger" onClick={toggleDropdown}>
              ☰
            </div>
            {dropdownVisible && (
            <div className="dropdown">
              <a href="#mother_menu">Mother Menu</a>
              <a href="#mother_cryptocurrency">Mother Cryptocurrency</a>
            </div>
          )}
          </div>
  
          <div className="navbar-links">
          <a >Mother</a>
          <div className="navbar-links">
  <Link to="/mining">Mining</Link>
  {/* Other links... */}
</div>
        
          <a >Wallet</a>
          <a >Exchange</a>
          <a >7DeFi</a>
          <a >Trade</a>
          <a >Crypto Market</a>
          <a >NFT</a>
        </div>
          <div className="auth-options">
          <div className="navbar-links">
    <Link to="/">Logout</Link>
    {/* Other links... */}
  </div>
          </div>
  
         
        </nav>
  
       
  
        {/* Add the full-width paragraph section */}
        <div className="full-width-paragraph">
        <h1>Start Mining</h1>
        {isMiningStarted ? (
          <div>
            <p>Mining is in progress. You can't start mining again until the countdown completes.</p>
            <p>Time remaining: {Math.floor(timer / 3600)} hours {Math.floor((timer % 3600) / 60)} minutes {timer % 60} seconds</p>
          </div>
        ) : (
          <button disabled={isMiningStarted} onClick={startMining}>
            Start Mining
          </button>
        )}
      </div>
  
       
  
  
  
  <div className="full-width-paragraph">
          <p>Copyright © 2024 Mother Wallet & Mother Exchange in the World Setup in Dubai. Website Design & Development By Wallet Software Team in Dubai</p>  
       
        </div>
  
  
  
  
      
  
  
  
      </div>
    );
  };

export default MiningPage;
